.social-icon-color-white {
  color: white;
}

.social-icon-color-black {
  color: black;
}

.social-icon-color-white:hover {
  color: var(--accent-color);
}