.make-page {
  height: 100vh;
  align-content: center;
  min-width: 100%;
}

.background-image {
  background-image: url("./media/watercolor-paper-texture.jpg");
  background-size: cover;
  min-width: 100%;
}

h2 {
  color: black;
  font-size: 50px;
  font-family: "Exo 2", sans-serif;
}

