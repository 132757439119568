.background-image-pic {
  /* The image used */
  background-image: url("../media/topImage.png");

  /* Full height */
  /* min-width: 100%; */
  /* height: 100%; */

  /* Center and scale the image nicely */
  background-position: center; 
  background-repeat: no-repeat;
  background-size: cover;
}

h1 {
  color: white;
  font-size: 100px;
  font-family: "Exo 2", sans-serif;
}

.secondary-title {
  color: white;
  font-size: 45px;
  font-family: "Exo 2", sans-serif;
}

.intro-div {
  background-color: #8080806e;
  width: fit-content;
  margin: auto;
  padding: 0 1em 0 1em;
}