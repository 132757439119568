.header-container {
  background: var(--primary-color);
  width: 100%;
}

.header-link:hover {
  color: var(--accent-color);
}

.header-link {
  color: white;
  font-size: 20px;
  font-weight: bold;
  font-family: "Exo 2", sans-serif;
}

a {
  cursor: pointer;
}

img {
  margin: -2em;
}