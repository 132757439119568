p {
  font-size: larger;
  font-weight: 500;
}

.background-about-image {
  /* The image used */
  background-image: url("../media/aboutPhoto.png");

  /* Full height */
  width: 50%;
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}